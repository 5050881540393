#overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.s-add-news-container {
    max-width: 768px;
    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        p {
            margin: 0;
        }

        .back-btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: $blue;
            cursor: pointer;

            p {
                font-size: 16px;
                font-weight: bold;
            }

            i {
                margin-right: 5px;
                display: flex !important;
            }
        }
    }
    .input-container {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &:nth-child(3) {
            margin-top: 30px;
        }

        label {
            font-size: 14px;
            color: $darkGray;
        }

        .date-input-wrapper {
            position: relative;
            width: 30%;
            min-width: 320px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            @media (max-width: 360px) {
                width: 100%;
                min-width: 250px;
            }
            .date-time-input {
                width: 100%;
                border-radius: 0;
                border-width: 1.5px;
                border-color: $blue;
                color: $blue;
                pointer-events: none;
                font-family: GalanoGrotesqueB;
                &:read-only {
                    background-color: $white;
                }
            }
            .icon-chevron {
                position: absolute;
                right: 6px;
                top: 5px;
                color: $blue;
            }
        }
        textarea {
            width: 100%;
            max-width: 360px;
            border-radius: 0;
            border-width: 1.5px;
            border-color: $blue;
            color: $blue;
            pointer-events: none;
            font-family: GalanoGrotesqueB;
            padding: 10px;
            pointer-events: auto;
            &::placeholder {
                color: $blue;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .button-container {
        margin-top: 20px;
        button {
            margin-right: 10px;
        }
    }
    .calendar-container {
        position: fixed;
        z-index: 100;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);

        &:before {
            position: absolute;
            content: "";
            top: -18px;
            left: 0;
            right: -18px;
            bottom: 0;
            border-radius: 20px;
            background-color: $blue;
        }

        i:hover {
            .calendar-button {
                color: $blue;
                font-size: 25px;
                &:hover {
                    background-color: $blue;
                }
            }
        }
        .calendar-wrapper {
            position: relative;

            .react-calendar {
                border-radius: 10px;
                padding-top: 10px;
                padding-right: 18px;
                padding-left: 18px;
                padding-bottom: 10px;
                z-index: 1;
                width: 360px;
                max-width: 100%;
                border: 0;
                border-right: 1px solid $gray;

                .react-calendar__navigation__label {
                    color: $blue;
                    font-size: 20px;
                    font-family: GalanoGrotesqueB;
                    pointer-events: none;
                    text-transform: uppercase;
                }
                abbr {
                    color: black;
                    text-decoration: none;
                }

                .react-calendar__navigation button {
                    background-color: $white;

                    &:focus {
                        outline: none;
                    }
                }

                &__tile {
                    position: relative;
                    font-size: 14px;
                    flex-basis: inherit;
                    max-width: inherit;
                    height: 46px;
                    padding: 0 !important;
                    background-color: transparent !important;

                    @media (max-width: 390px) {
                        height: 36px;
                    }

                    abbr {
                        position: relative;
                        z-index: 10;
                    }

                    &:hover,
                    &:focus {
                        color: $white;
                        outline: none !important;
                    }

                    &:disabled {
                        background-color: transparent;

                        abbr {
                            color: darken($gray, 5%);
                        }
                    }

                    &--active {
                        &:after {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 30px;
                            height: 30px;
                            display: block;
                            content: "";
                            background: $beige;
                            border-radius: 40px;
                        }
                    }

                    &--hasActive {
                        &:hover {
                            &:after {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                width: 30px;
                                height: 30px;
                                display: block;
                                content: "";
                                background: $beige;
                                border-radius: 40px;
                                z-index: -10;
                            }
                        }
                    }
                }

                .react-calendar__month-view__weekdays__weekday {
                    abbr {
                        font-family: GalanoGrotesqueB;
                    }
                }
            }
        }
    }
}
