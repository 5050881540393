@font-face {
    font-family: GalanoGrotesqueB;
    src: url("../assets/fonts/galano_cufonfonts/GalanoGrotesqueBold.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueSB;
    src: url("../assets/fonts/galano_cufonfonts/GalanoGrotesqueSemiBold.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueR;
    src: url("../assets/fonts/galano_cufonfonts/GalanoGrotesqueRegular.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueL;
    src: url("../assets/fonts/galano_cufonfonts/GalanoGrotesqueLight.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueEL;
    src: url("../assets/fonts/galano_cufonfonts/GalanoGrotesqueExtraLight.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: GalanoGrotesqueT;
    src: url("../assets/fonts/galano_cufonfonts/GalanoGrotesqueThin.otf") format("woff2");
    font-weight: normal;
    font-style: normal;
}
