.s-prod-details-container {
    max-width: 900px;
    margin-bottom: 120px;

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
            margin: 0;
        }
        .back-btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: $blue;
            line-height: 1;
            cursor: pointer;
            p {
                font-size: 16px;
                font-family: GalanoGrotesqueB;
            }
            i {
                margin-right: 5px;
                display: flex !important;
            }
        }
    }
    .prod_container {
        margin: auto;
        padding: 20px;
        .ordernum_container {
            position: relative;
            align-items: center;
            justify-content: center;
            width: 100%;

            label {
                font-size: 14px;
                color: $darkGray;
            }

            .ordernum_input {
                border-radius: 0;
                border-width: 1.5px;
                border-color: $blue;
                font-family: GalanoGrotesqueB;
                color: $blue;

                &::placeholder {
                    color: $blue;
                    font-size: 16px;
                }
                &:disabled {
                    background-color: $white;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
}
