.alert-container {
    position: fixed !important;
    width: 100%;
    top: 0;
    margin-bottom: 0 !important;
    z-index: 999999999;
    display: flex;
    justify-content: space-between;
    i {
        cursor: pointer;
    }
    .alert-msg {
        margin: 0;
    }
}
