
.modal {
    .modal-dialog {
        max-width: 768px !important;
    }

    .miyosmart-pop-up-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
        }

        &__outer-layer {
            position: absolute;
            display: block;
            top: 0;
            right: 0px;
            bottom: 20px;
            left: 20px;
            border-radius: 20px;
            background-color: $blue;
            z-index: -10;
        }

        &__body {
            padding: 35px 20px;
            width: 100%;
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 20px;
        }

        .miyosmart-pop-up-content {
            padding: 20px;
            border: 0;
            background-color: transparent;

            .miyosmart-pop-up-header {
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: flex-end;
                border-bottom: 0;
                i {
                    color: $blue;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
            .modal-body {
                padding: 0;
                width: 100%;
                max-width: 500px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
