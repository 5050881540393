.topbar {
    min-width: 320px;
    padding: 25px 35px;
    background-color: $beige;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 25px 15px;
    }

    .navLeftsideElements {
        .hoyaLogo {
            width: 170px;

            @media (max-width: 768px) {
                width: 130px;
            }

            img {
                width: 100%;
            }
        }
    }

    .navRightSideElements {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 768px) {
            margin-top: 15px;
        }

        .RightSideElementstyle {
            position: relative;
            margin: 0 15px;
            color: $blue;
            font-size: 18px;
            font-family: GalanoGrotesqueB;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .profile-name {
            position: relative;
            margin: 0 15px;
            color: $blue;
            font-size: 18px;
            font-family: GalanoGrotesqueB;
            width: 100%;
            max-width: 220px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .dropdownBellOptionContainer {
            position: absolute;
            width: 300px;
            height: 200px;
            top: 40px;
            right: -10px;
            border-radius: 7px;
            border: 1px solid $blue;
            background-color: white;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            z-index: 99999999;

            @media (max-width: 768px) {
                position: fixed;
                top: 110px;
                left: 10px;
                right: 10px;
                width: calc(100% - 20px);
            }

            .notification-list {
                padding: 15px;
                font-size: 14px;
                border-bottom: 1px solid $blue;
                cursor: pointer;

                &.seen {
                    background-color: darken(white, 5%);
                }
            }
        }

        @media screen and (min-width: 768px) {
            .dropdownContainer {
                display: none;
                position: relative;
                align-self: center;
                margin: 0 15px;
                color: $blue;
                font-weight: 600;
            }
        }

        .dropdownContainer {
            position: relative;
            align-self: center;
            margin-left: 15px;
            margin-right: 15px;
            color: $blue;
            font-weight: 600;

            .dropdownChevronLogo {
                margin-right: 16px;
                align-self: center;
                color: $blue;
            }

            .dropdownOptionContainer {
                position: absolute;
                margin-top: 10px;
                right: 0;
                z-index: 1000;
                background-color: white;
                border-radius: 7px;
                border: 1.5px solid $blue;
                display: flex;
                flex-direction: column;

                i {
                    position: relative;
                    top: -2px;
                    right: 10px;
                    padding-left: 10px;
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                    &:first-child {
                        .dropdownElements {
                            &:hover {
                                border-top-left-radius: 7px;
                                border-top-right-radius: 7px;
                            }
                        }
                    }
                    &:last-child {
                        .dropdownElements {
                            &:hover {
                                border-bottom-left-radius: 7px;
                                border-bottom-right-radius: 7px;
                            }
                        }
                    }
                }
                .dropdownElements {
                    display: flex;
                    justify-content: flex-start;
                    color: #2f368f;
                    font-weight: 600;
                    font-size: 16px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 15px;
                    padding-right: 140px;
                    white-space: nowrap;
                    cursor: pointer;
                    &.logout-btn {
                        border-bottom-left-radius: 7px;
                        border-bottom-right-radius: 7px;
                    }
                    &:hover {
                        background-color: #2f368f;
                        color: white;
                    }
                }
            }
        }
    }
}
