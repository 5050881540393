.card_container {
    width: 100%;
    max-width: 860px;
    height: 100%;
    @media (max-width: 768px) {
        width: 100%;
    }
    .card {
        margin-top: 30px;
        margin-bottom: 50px;
        border-radius: 10px;
        .card-body {
            flex-direction: row;
            display: flex;
            .cardhalf_container {
                width: 60%;
                div {
                    width: 70%;
                    min-height: 38px;
                    border-bottom: 1.5px solid $gray;
                    &:last-child {
                        width: 80%;
                        border-bottom: 0;
                        display: flex;
                        flex-direction: column;

                        @media (min-width: 640px) {
                            flex-direction: row;
                        }
                        div {
                            display: flex;
                            flex-direction: column;
                            border-bottom: 0;
                        }
                    }
                }
                @media (max-width: 786px) {
                    width: 70%;
                }
            }
            .half_container,
            .minhalf_container {
                display: grid;
                width: 20%;
                @media (max-width: 786px) {
                    width: 30%;
                }
                p {
                    min-height: 38px;
                    border-bottom: 1.5px solid $gray;
                    &:last-child {
                        border: none;
                    }
                }
            }
            label {
                color: $darkGray;
                padding-top: 20px;
            }
            p {
                min-height: 38px;
                margin: 0;
                padding-bottom: 10px;
            }
        }
        .contact_container {
            padding-left: 1.25em;
            padding-right: 1.25em;
            padding-bottom: 1.25em;
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: none;
            .contact_name {
                display: grid;
                border-top: 1.5px solid$gray;
                padding-top: 1.25em;
                border-bottom: 1.5px solid$gray;
                width: 60%;
            }
            .contact_number {
                display: grid;
                padding-top: 1.25em;
                border-top: 1.5px solid$gray;
                border-bottom: 1.5px solid$gray;
                width: 40%;
            }
            .contact_content {
                display: grid;
                border-bottom: 1.5px solid$gray;
                width: 100%;
                label {
                    color: $darkGray;
                }
                text {
                }
            }
        }
        .appointment_container {
            padding-left: 1.25em;
            padding-right: 1.25em;
            padding-bottom: 1.25em;
            i {
                display: flex;
                position: absolute;
                left: 7em;
                color: $blue;
                cursor: pointer;
            }
            ol {
                counter-reset: item;
                margin-left: -40px;

                li {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    margin: 8px 0;
                    font-size: 14px;
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: underline;
                    }
                    @media (max-width: 640px) {
                        flex-direction: column;
                    }

                    &.is-upcomming {
                        color: $blue;
                    }
                }
            }

            label {
                color: $darkGray;
            }

            text {
            }

            .request-slot {
                color: $red;
                width: 50%;
                text-align: right;
                @media (max-width: 640px) {
                    margin: 5px 0;
                    text-align: left;
                    width: 100%;
                }
            }
        }
    }
}
