.s-ecp-add-container {
    .ecp-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;
        .ecp-title {
            font-size: 35px;
            font-weight: bold;
            color: $blue;
            .add-icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }
    }
    .form-container {
        .form-wrapper {
            width: 100%;
            max-width: 1240px;
            display: flex;
            flex-direction: row;
            .input-col {
                width: 65%;
                padding-right: 10px;
                &:last-child {
                    padding-left: 15px;
                    width: 30%;
                }
                .input-container {
                    margin: 15px 0;
                    label {
                        margin: 2.5;
                        font-weight: 600;
                        color: $blue;
                    }
                    .btn-group {
                        width: 100%;
                        .dropdown-input {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        .dropdown-menu {
                            right: 0;
                            padding: 0;
                            border-radius: 0;
                            border: 1px solid $gray;
                            background-color: $white;
                            overflow: hidden;
                            overflow-y: scroll;
                            max-height: 200px;
                            .dropdown-item {
                                font-weight: bold;
                                color: $darkGray;
                                border-bottom: 1px solid $gray;
                                &:hover {
                                    background-color: $blue;
                                    color: $white;
                                }
                                &:last-child {
                                    border: 0;
                                }
                            }
                        }
                    }
                    .text-input,
                    .textarea-input,
                    .dropdown-input {
                        height: 40px;
                        width: 100%;
                        color: $blue;
                        border-radius: 0;
                        font-size: 16px;
                        font-family: GalanoGrotesqueB;
                        border: 1.5px solid $blue;
                        background-color: $white;
                        &.error {
                            border-color: $red;
                        }
                        &:focus,
                        &:active {
                            outline: none;
                            box-shadow: none;
                        }
                        &:disabled {
                            background-color: $white;
                        }

                        &.input-date,
                        &.input-ecpCode {
                            color: $darkGray;
                            background-color: darken($gray, 5%);
                            border-color: darken($gray, 5%);
                        }
                        &.input-date {
                            width: 120px;
                        }
                        &.input-pic,
                        &.input-contact,
                        &.input-email {
                            max-width: 320px;
                        }
                    }
                    .input-feedback {
                        margin-top: 5px;
                        color: $red;
                        font-size: 12px;
                        font-family: GalanoGrotesqueB;
                    }
                    .textarea-input {
                        padding: 10px;
                        height: auto;
                        min-height: 100px;
                    }
                }
                .button-container {
                    button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
