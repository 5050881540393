.s-add-promo-container {
    max-width: 1024px;
    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
            margin: 0;
        }
        .back-btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: $blue;
            line-height: 1;
            cursor: pointer;
            p {
                font-size: 16px;
            }
            i {
                margin-right: 5px;
            }
        }
    }
    .drop-file-zone {
        width: 100%;
        min-height: 200px;
        margin-bottom: 30px;
        border-radius: 15px;
        section {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            flex-direction: column;
            &:focus {
                outline: none;
            }
            .drop-file {
                flex: 1;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                &:focus {
                    outline: none;
                }
                .desc {
                    color: $blue;

                    font-size: 23px;
                    margin: 0;
                    text-align: center;
                    pointer-events: none;
                }
            }
        }
    }
    .card {
        border: 0;
        border-radius: 10px;
        .banner-list-container {
            padding: 0;
            margin: 0;
            .banner-item {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 5px;
                .thumbnail {
                    width: 70px;
                    height: 35px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .desc {
                    width: 70%;
                    margin: 0;
                    padding: 0 10px;
                    font-size: 14px;
                    color: $blue;
                    text-align: left;
                    display: flex;
                    align-items: center;
                }
                i {
                    cursor: pointer;
                    margin: 0 2.5px;
                    color: $blue;
                }
            }
        }
    }
    .button-container {
        margin-top: 20px;
        button {
            margin-right: 10px;
        }
    }
}
