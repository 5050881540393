.s-ecp-dashboard-container {
    margin-bottom: 120px;

    .ecp-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;
        .ecp-title {
            font-size: 35px;
            color: $blue;
            .add-icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }
    }
    .search-container {
        position: relative;
        width: 50%;
        min-width: 320px;
        margin-bottom: 35px;
        @media (max-width: 486px) {
            width: 100%;
        }

        label {
            font-size: 14px;
            color: $darkGray;
        }

        .icon-search {
            position: absolute;
            top: 2.1em;
            right: 3%;
            color: $blue;
        }

        .search-input {
            border-radius: 0;
            border-width: 1.5px;
            border-color: $blue;
            font-family: GalanoGrotesqueB;
            display: flex;
            align-items: center;

            &:focus {
                outline: none;
                box-shadow: none;
            }
            &::placeholder {
                color: $blue;
                font-size: 16px;
            }
        }
    }
    .card-container {
        .card {
            .card-body {
                max-height: 100%;
                table {
                    thead {
                        tr {
                            white-space: nowrap;
                        }
                    }
                    tbody {
                        tr {
                            .edit {
                                text-align: right;
                                white-space: nowrap;
                            }
                            .name {
                                min-width: 100px;
                            }
                            .email {
                                word-break: break-all;
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}
