.super-container {
    margin-bottom: 120px;

    &.s-rewards-dash-container {
        width: 100%;
        .card-container {
            .card {
                .card-body {
                    max-height: 100%;
                    table {
                        thead {
                            tr {
                                th {
                                }
                            }
                        }
                        tbody {
                            tr {
                                .action {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
