.remove-modal-pop-up {
    overflow: hidden;
    .modal-content {
        overflow-x: hidden;
        height: auto !important;
        min-height: 280px;
        .popup-container {
            width: 100%;
            text-align: center;

            .popup-title-container {
                margin-bottom: 30px;

                .popup-title {
                    font-size: 35px;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                    text-transform: uppercase;
                }
            }

            .popup-button-container {
                display: flex;

                button {
                    margin-right: 10px;
                }
            }
        }
    }
}
