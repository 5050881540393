.s-add-article-container {
    max-width: 768px;
    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
            margin: 0;
        }
        .back-btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: $blue;
            line-height: 1;
            cursor: pointer;
            p {
                font-size: 16px;
                font-family: GalanoGrotesqueB;
            }
            i {
                margin-right: 5px;
                display: flex !important;
            }
        }
    }
    .form-container {
        .form-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            .input-container {
                margin: 15px 0px;
                label {
                    font-size: 14px;
                    color: $darkGray;
                }
                .text-input {
                    max-width: 350px;
                    height: 40px;
                    width: 100%;
                    color: $blue;
                    border-radius: 0;
                    font-size: 16px;
                    font-family: GalanoGrotesqueB;
                    border: 1.5px solid $blue;
                    background-color: $white;

                    &.error {
                        border-color: $red;
                    }
                    &:focus,
                    &:active {
                        outline: none;
                        box-shadow: none;
                    }
                    &:disabled {
                        background-color: darken($gray, 5%);
                    }
                }
                .input-feedback {
                    margin-top: 5px;
                    color: $red;
                    font-size: 12px;
                    font-family: GalanoGrotesqueB;
                }
                .textarea-input {
                    max-width: 550px;
                    padding: 10px;
                    height: auto;
                    min-height: 100px;
                }
            }
            .drop-file-zone {
                width: 100%;
                min-height: 200px;
                margin-bottom: 10px;
                border-radius: 0;
                border: 1.5px solid $blue;

                &--disabled {
                    opacity: 0.5;
                }

                section {
                    flex-grow: 1;
                    display: flex;
                    align-items: stretch;
                    flex-direction: column;
                    &:focus {
                        outline: none;
                    }
                    .drop-file {
                        flex: 1;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        &:focus {
                            outline: none;
                        }
                        .desc {
                            color: $blue;
                            font-size: 23px;
                            margin: 0;
                            text-align: center;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
    .button-container {
        margin-top: 20px;
        button {
            margin-right: 10px;
        }
    }
}
