.card-container {
    &.miyo-points-summary {
        width: 100%;
        .card {
            .card-body {
                max-height: 100%;
                .table {
                    thead {
                        th {
                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                    td {
                        &:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    .card-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card-title {
            font-size: 35px;
            font-family: GalanoGrotesqueB;
            color: $blue;

            .add-icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }

        .back-btn-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.75rem;
            margin-right: 2rem;
            color: $blue;
            cursor: pointer;
            p {
                margin: 0;
                margin-left: 5px;
            }
            i {
                line-height: 1;
                display: flex !important;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .card {
        border: 0;

        .no-records {
            td {
                padding: 10px 10px 10px 0;
                color: $red !important;
                font-size: 18px !important;
                font-weight: 800 !important;
            }
        }

        .card-body {
            padding: 0;
            max-height: 220px;
            overflow: scroll;

            .table {
                margin: 0;
                thead {
                    th {
                        padding: 0 10px 10px 0;
                        font-size: 20px;
                        color: $blue;
                        font-family: GalanoGrotesqueB;
                        text-transform: uppercase;
                        border: 0;
                        border-bottom: 1px solid $blue;
                    }
                }

                td {
                    font-size: 16px;
                    color: $blue;
                    font-weight: 500;

                    &.action {
                        &.disabled {
                            color: $darkGray;
                        }
                    }

                    i {
                        cursor: pointer;
                    }

                    .image-container {
                        width: 100px;
                        height: 50px;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }

            .pagination {
                margin-top: 15px;
                justify-content: center;
                li {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    height: 30px;
                    margin: 5px;
                    background-color: $white;
                    border: 1px solid $blue;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    &.active {
                        background-color: $blue;
                        a {
                            color: $white;
                        }
                    }
                    &.disabled {
                        border: 1px solid $darkGray;
                        pointer-events: none;
                    }
                    &:hover {
                        background-color: $blue;
                        a {
                            color: $white;
                        }
                    }
                    a {
                        color: $darkGray;
                        font-size: 12px;
                        line-height: 1;
                        text-decoration: none;
                        user-select: none;
                    }
                    &.pagination-first-child,
                    &.pagination-last-child {
                        width: 75px;
                        min-width: 75px;
                    }
                }
            }
        }
    }
}
