.contactpass_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .contactpass_text {
        font-size: 25px;
        color: #000000;
        font-weight: 300;
    }
}
