.s-ecp-edit-container {
    margin-bottom: 120px;

    .ecp-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;
        .ecp-title {
            font-size: 35px;
            font-family: GalanoGrotesqueB;
            color: $blue;
            .add-icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }
    }
    .form-container {
        .form-wrapper {
            width: 100%;
            max-width: 1240px;
            display: flex;
            flex-direction: row;
            .input-col {
                width: 65%;
                padding-right: 10px;
                &:last-child {
                    padding-left: 15px;
                    width: 30%;
                }
                .input-container {
                    margin: 15px 0;

                    label {
                        font-size: 14px;
                        color: $darkGray;
                    }

                    .text-input,
                    .textarea-input {
                        height: 40px;
                        width: 100%;
                        color: $blue;
                        border-radius: 0;
                        font-size: 16px;
                        font-family: GalanoGrotesqueB;
                        border: 1.5px solid $blue;
                        &.error {
                            border-color: $red;
                        }
                        &:focus,
                        &:active {
                            outline: none;
                            box-shadow: none;
                        }
                        &:disabled {
                            background-color: $white;
                        }
                        &::placeholder {
                        }
                        &.input-date,
                        &.input-ecpCode {
                            color: $darkGray;
                            background-color: darken($gray, 5%);
                            border-color: darken($gray, 5%);
                        }
                        &.input-date {
                            width: 120px;
                        }
                        &.input-pic,
                        &.input-contact,
                        &.input-email {
                            max-width: 320px;
                        }
                    }
                    .input-feedback {
                        margin-top: 5px;
                        color: $red;
                        font-size: 12px;
                        font-family: GalanoGrotesqueB;
                    }
                    .textarea-input {
                        padding: 10px;
                        height: auto;
                        min-height: 100px;
                    }
                }
                .button-container {
                    button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
