.clientlist_container {
    padding: 50px;

    @media (max-width: 640px) {
        padding: 25px;
    }

    .clientlist_search {
        color: $darkGray;
        .search-wrapper {
            display: flex;
            align-items: flex-end;
            width: 100%;
            max-width: 400px;
            margin-bottom: 50px;

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .form-group {
                width: 100%;
            }
            .dropdown {
                margin-left: 15px;
                margin-bottom: 1rem;

                @media (max-width: 768px) {
                    margin-left: 0;
                    width: 100%;
                }

                .dropdown-toggle {
                    color: $blue;
                    background-color: $white;
                    border-radius: 0;
                    border: 1.5px solid $blue;
                    font-family: GalanoGrotesqueB;

                    &:focus,
                    &:active {
                        box-shadow: none;
                        outline: none;
                    }

                    @media (max-width: 768px) {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    border-radius: 0;
                    border: 1px solid $blue;
                    background-color: $white;
                    overflow: hidden;
                    max-height: 200px;

                    .dropdown-item {
                        padding: 10px;
                        color: $darkGray;
                        border-bottom: 1px solid $blue;

                        &:hover {
                            background-color: $blue;
                            color: $white;
                        }

                        &:last-child {
                            border: 0;
                        }
                    }
                }
            }
        }

        ::placeholder {
            color: $blue;
            font-size: 16px;
            font-family: GalanoGrotesqueB;
        }
        .input_container {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 400px;
            border: 1px solid $blue;
            padding: 0 15px 0 0;

            .icon_search {
                color: $blue;
            }

            .clientlist_input {
                border: 0;
            }
        }
        input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}
