.s-edit-promo-container {
    max-width: 1024px;
    .banner-name {
        margin: 15px 0;
        padding: 10px 15px;
        border-radius: 50px;
        color: $blue;
        font-weight: 600;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
        i {
            cursor: pointer;
        }
    }
    .preview-container {
        margin: 10px 0;
        background-color: $white;
        .image-container {
            width: 100%;
            height: 400px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .drop-file-zone {
        width: 100%;
        min-height: 200px;
        margin-bottom: 30px;
        border-radius: 15px;
        section {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            flex-direction: column;
            &:focus {
                outline: none;
            }
            .drop-file {
                flex: 1;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                &:focus {
                    outline: none;
                }
                .desc {
                    color: $blue;

                    font-size: 23px;
                    margin: 0;
                    text-align: center;
                    pointer-events: none;
                }
            }
        }
    }
    .button-container {
        margin-top: 20px;
        button {
            margin-right: 10px;
        }
    }
}
