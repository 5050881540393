@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "./fonts.scss";

@import "./theme.scss";
@import "./login.scss";
@import "./forgetPassword.scss";

@import "./components/button.scss";
@import "./components/footer.scss";
@import "./components/title.scss";
@import "./components/login-card.scss";
@import "./components/top-nav.scss";
@import "./components/layout.scss";
@import "./components/home-layout.scss";
@import "./components/card-table.scss";
@import "./components/pop-up.scss";
@import "./components/admin/corp-card.scss";
@import "./components/admin/client-card.scss";
@import "./components/super-admin/client-card.scss";
@import "./components/super-admin/promo-card.scss";

@import "./admin/admin-home.scss";
@import "./admin/corporate-info.scss";
@import "./admin/appt-calendar.scss";
@import "./admin/create-appt.scss";
@import "./admin/client-list.scss";
@import "./admin/contact-us.scss";
@import "./admin/edit-appt.scss";
@import "./admin/client-details.scss";
@import "./admin/warranty-request.scss";
@import "./admin/appt-history.scss";
@import "./admin/reset-password.scss";
@import "./admin/reset-password-completed.scss";

@import "./super-admin/home.scss";
@import "./super-admin/add-news-list.scss";
@import "./super-admin/add-csv-file.scss";
@import "./super-admin/ecp-dashboard.scss";
@import "./super-admin/ecp-add.scss";
@import "./super-admin/ecp-edit.scss";
@import "./super-admin/product-dashboard.scss";
@import "./super-admin/product-details.scss";
@import "./super-admin/media-dashboard.scss";
@import "./super-admin/media-add-video.scss";
@import "./super-admin/media-delete-popup.scss";
@import "./super-admin/media-edit-video.scss";
@import "./super-admin/media-add-article.scss";
@import "./super-admin/media-edit-article.scss";
@import "./super-admin/media-add-research.scss";
@import "./super-admin/media-edit-research.scss";
@import "./super-admin/rewards-add.scss";
@import "./super-admin/rewards-edit.scss";
@import "./super-admin/rewards-dashboard.scss";
@import "./super-admin/redemptions-dashboard.scss";
@import "./super-admin/redemptions-details.scss";
@import "./super-admin/add-promotion.scss";
@import "./super-admin/edit-promotion.scss";

body {
    margin: 0;
    font-family: GalanoGrotesqueR;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
