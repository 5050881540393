.corpmain_container {
    display: flex;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    }

    .card_container_one {
        width: 65%;
    }

    .card_container_two {
        width: 35%;
        margin-left: 80px;

        .card-body {
            padding: 15px 0 !important;
        }

        @media screen and (max-width: 960px) {
            margin-left: 0;
            max-width: 100%;

            .smCard_Container {
                margin-left: 0;
            }
        }
    }

    .card {
        border: 0;

        .card_body {
            padding: 15px 0 !important;

            .corp_container {
                padding-left: 30px;
                border-bottom: 1.5px solid $blue;
                display: flex;
                justify-content: space-between;

                i {
                    position: absolute;
                    left: 0;
                    color: $blue;
                }
            }
        }
    }

    .crop-text {
        font-weight: 700;
        word-break: break-all;
    }

    .edit-button {
        cursor: pointer;
    }

    label {
        color: $darkGray;
        font-size: 12px;
        font-weight: 500;
    }
}

.contact_container,
.fax_container,
.website_container,
.address_container,
.country_container,
.email_container {
    padding-left: 30px;
    border-bottom: 1.5px solid $blue;
    display: flex;
    justify-content: space-between;

    i {
        position: relative;
        color: $blue;
    }
}

@media screen and (max-width: 550px) {
    .corp_container,
    .contact_container,
    .fax_container,
    .website_container,
    .businesshour_container,
    .address_container,
    .country_container {
        flex-wrap: wrap;
        width: 100%;
        i {
            position: absolute;
            right: 0.5em;
        }
        p {
            width: 55%;
        }
    }
}

.left_icon,
.left_pass_icon {
    position: absolute;
    color: $blue;
}

.password_container {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    font-size: 14px;

    i {
        position: relative;
        color: $blue;
    }

    p {
        font-weight: bold;
        color: $red;
    }
}

.businesshour_container {
    position: relative;
    justify-content: space-between;
    padding-left: 30px;
    border-bottom: 1.5px solid $gray;
    font-size: 14px;
    .left_icon {
        left: 0;
    }
    i {
        position: absolute;
        top: 0;
        right: 0;
        color: $blue;
    }
    .businesshour_details {
        .business-hrs-row {
            display: flex;
            justify-content: space-between;
        }
        p {
            margin-right: 30px;
            width: 100%;
        }
        .sunday_closed {
            color: $red;
        }
    }
}

.corp-modal-pop-up {
    overflow: hidden;

    .modal-content {
        overflow-x: hidden;
        height: auto !important;
        min-height: 350px;
        max-height: 95% !important;

        .popUp_container {
            width: 100%;
            text-align: center;

            .popUp_content {
                position: relative;
                margin-top: 35px;
                z-index: 999999;

                .formStyle {
                    width: 100%;
                    .input-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .input-label {
                            color: $blue;
                            font-size: 16px;
                            font-family: GalanoGrotesqueB;
                            margin: 0 15px 0 0;
                        }

                        .input-business-hrs {
                            width: 50%;
                        }
                    }

                    @media (max-width: 360px) {
                        .input-container {
                            .input-business-hrs {
                                width: 80%;
                            }
                        }
                    }
                    .text-input {
                        height: 50px;
                        width: 100%;
                        margin: 10px 0;
                        border: none;
                        color: $blue;
                        font-family: GalanoGrotesqueB;
                        border: 1.5px solid $blue;
                        border-radius: 100px;
                        text-decoration: none;
                        text-align: center;
                        display: flex;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            color: $blue;
                            opacity: 1;
                        }

                        &.error {
                            color: $red;
                            border-color: $red;
                        }
                    }
                    .input-feedback {
                        font-size: 12px;
                        color: $red;
                    }
                    .button-container {
                        width: 100%;
                        text-align: center;
                        display: flex;

                        @media screen and (max-width: 640px) {
                            flex-wrap: wrap;
                            justify-content: center;
                        }

                        .miyosmart-button {
                            margin: 10px 5px 5px;

                            &.outline {
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
