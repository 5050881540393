.adminhome_container {
    padding: 50px;

    @media (max-width: 640px) {
        padding: 25px;
    }

    .adminhome_apptable {
        position: relative;
        margin-top: 30px;
    }
}
