$primaryColor: #2f368f;
$labelColor: rgb(153, 153, 153);
// .rewardsRedemption-container {
//   @media only screen and (max-width: 767px) {
//     width: 90%;
//     max-width: 90%;
//     margin-left: 10px;
//   }
//   @media only screen and (max-width: 500px) {
//     margin: 0;
//     width: 100%;
//     max-width: 100%;
//   }
//   @media only screen and (max-width: 400px) {
//     margin: 0;
//     padding: 0 !important;
//   }

.row-container {
    @media only screen and (max-width: 680px) {
        flex-direction: column;
    }
    .col-4 {
        @media only screen and (max-width: 680px) {
            max-width: 100%;
        }
    }
    .redemption-chart-container,
    .shipping-card-container,
    .redemption-summary-container {
        width: 100%;

        .card-title {
            font-size: 28px;
            color: $primaryColor;
            @media only screen and (max-width: 430px) {
                font-size: 22px;
            }
        }
    }
    .shipping-card-container {
        width: 100%;
        .shipping-details-card {
            padding: 15px;
            border-radius: 10px;
            .sd-card-role {
                margin: 0;
                width: 100%;
                border-bottom: 1px solid $labelColor;
                pointer-events: none;
                &:last-child {
                    border: 0;
                }
                .col {
                    padding: 10px;
                    pointer-events: auto;

                    &.edit-col {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $primaryColor;
                        cursor: pointer;
                        pointer-events: auto;
                    }
                    .label {
                        font-size: 14px;
                        color: $labelColor;
                        margin: 0;
                        @media only screen and (max-width: 380px) {
                            font-size: 12px;
                        }
                    }
                    .desc {
                        margin: 0;
                        font-size: 16px;
                        line-height: 1.2;
                        @media only screen and (max-width: 380px) {
                            font-size: 14px;
                        }
                    }
                    .sd-details-icon-style {
                        color: $primaryColor;
                    }
                }
                .col-1 {
                    @media only screen and (max-width: 768px) {
                        padding-right: 30px;
                    }
                }
            }
        }
    }
}
// }
