.layoutContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .verticalFLexContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        .vflex1-sidebar {
            width: 100%;
            max-width: 280px;
            background-color: $blue;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @media (max-width: 768px) {
                display: none;
            }

            .profilePicAndNameCointainer {
                position: relative;
                margin: 0 auto;
                margin-top: 20px;
                .profile-name {
                    font-weight: 600;
                    font-size: 18px;
                    color: $white;
                    width: 100%;
                    line-height: 1.2;
                    word-break: break-word;
                    padding: 15px;
                }
            }
            a {
                text-decoration: none;
            }
            .side-bar-element {
                display: flex;
                align-items: center;
                color: $white;
                padding: 25px 0 25px 50px;
                font-weight: 500;
                font-size: 16px;
                cursor: pointer;

                @media (max-height: 680px) {
                    padding: 15px 20px 20px 20%;
                }

                &.activated {
                    color: $beige;
                    border-right: 10px solid $beige;
                    background-color: rgba(0, 0, 0, 0.1);
                }

                &:active {
                    outline: none !important;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                .side-bar-icon {
                    position: relative;
                    padding-right: 15px;
                }
            }
        }
        .vflex2 {
            width: 100%;
            height: calc(100% - 80px);
            overflow: scroll;

            .logoAskQuestionStyle {
                position: absolute;
                bottom: 15px;
                right: 15px;
                width: 100px;
                height: auto;
                z-index: 9999;
                cursor: pointer;
            }
        }
    }
}
