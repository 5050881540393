.s-media-dashboard-container {
    margin-bottom: 120px;

    .media-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;

        &--with-top {
            margin-top: 50px;
        }

        .media-title {
            font-size: 35px;
            color: $blue;
            font-family: GalanoGrotesqueB;

            .add-icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }
    }

    .card-container {
        .card {
            .card-body {
                max-height: 400px;
            }
        }
    }
}

.remove-modal-pop-up {
    overflow: hidden;
    .modal-content {
        overflow-x: hidden;
        height: auto !important;
        min-height: 280px;
        .popup-container {
            width: 100%;
            text-align: center;

            .popup-title-container {
                margin-bottom: 30px;

                .popup-title {
                    font-size: 35px;
                    color: $blue;
                }
            }

            .popup-button-container {
                button {
                    margin-right: 10px;
                }
            }
        }
    }
}
