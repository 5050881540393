#overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.edit-appt-calendar {
    position: fixed;
    z-index: 100;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    .calendar-wrapper {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            top: -20px;
            left: 0;
            right: -20px;
            bottom: 0;
            border-radius: 20px;
            background-color: $blue;
        }

        .appt-calendar {
            display: flex;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    i:hover {
        .calendar_button {
            color: $blue;
            font-size: 25px;
            &:hover {
                background-color: $blue;
            }
        }
    }
    .react-calendar {
        position: relative;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 100%;
        max-width: 550px;
        min-width: 325px;
        padding: 30px;
        z-index: 1;
        border: 0;
        border-right: 1px solid $gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 600px) {
            width: 100%;
            max-width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
        }

        &__navigation {
            width: 100%;

            &__label {
                color: $blue;
                font-family: GalanoGrotesqueB;
                font-size: 20px;
                pointer-events: none;
                text-transform: uppercase;
            }
        }

        &__month-view__weekdays__weekday {
            padding: 5px 0 20px;
        }

        &__tile {
            padding: 0 !important;
            &:disabled {
                background-color: transparent;

                abbr {
                    color: darken($gray, 5%);
                }
            }

            &--active {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    content: "";
                    background: $beige;
                    border-radius: 40px;
                    z-index: -10;
                }
            }

            &--hasActive {
                &:hover {
                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 30px;
                        height: 30px;
                        display: block;
                        content: "";
                        background: $beige;
                        border-radius: 40px;
                        z-index: -10;
                    }
                }
            }
        }

        abbr {
            text-decoration: none;
            font-size: 14px;
            color: black;
            z-index: 999999;
        }

        button {
            position: relative;
            background-color: transparent !important;
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
            flex-basis: inherit;
            max-width: inherit;
            height: 46px;
            @media (max-width: 390px) {
                height: 36px;
            }
            &:hover,
            &:focus {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    content: "";
                    background: $beige;
                    border-radius: 40px;
                    z-index: -10;
                }
            }
        }

        .react-calendar__navigation button {
            background-color: $white;
            &:hover {
                background-color: $blue;
                color: $white;
                border-radius: 40px;
            }
            &:focus {
                outline: none;
            }
        }

        .react-calendar__month-view__weekdays__weekday {
            &:nth-child(6) {
                color: $red;
            }
            &:last-child {
                color: $red;
            }
        }
    }

    .timeselect_container {
        background-color: white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 1;
        width: 100%;
        max-width: 250px;

        @media (max-width: 600px) {
            border-top-right-radius: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            max-width: 100%;
        }

        .timeselect_title {
            display: grid;
            justify-content: center;
            text-align: center;
            padding: 20px;

            .miyosmart-title {
                font-size: 18px !important;
            }

            button {
                margin: 5px;
                padding: 10px 15px;
                border: none;
                color: black;
                font-size: 14px;
                font-family: GalanoGrotesqueB;
                border-radius: 20px;
                background-color: $white;

                &:focus {
                    outline: 0;
                }
                &.active {
                    background-color: $beige;
                }

                &:disabled {
                    opacity: 0.5;
                    text-decoration: line-through;
                    color: $darkGray;
                    border: 1px solid $darkGray;
                    background-color: $white;
                }
            }
        }
    }
}

.editappt_container {
    margin: auto;
    padding: 30px;

    label {
        font-size: 14px;
    }

    .editappt_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }

        .cancel-button {
            color: $blue;
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
            font-family: GalanoGrotesqueB;
        }
    }

    .editappt_search {
        color: $darkGray;

        .ordernum_container {
            position: relative;
            padding: 0 15px 0 0;
            width: 100%;
            max-width: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            border: 1px solid $blue;

            .icon_search {
                color: $blue;
            }
            .ordernum_input {
                border: 0;
                border-radius: 0;
                font-family: GalanoGrotesqueB;
                color: $blue;
                background-color: transparent;

                &::placeholder {
                    font-family: GalanoGrotesqueB;
                    color: $blue;
                }
            }
            input {
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        ::placeholder {
            color: $blue;
            font-size: 16px;
        }
    }

    .datetime_container {
        margin-top: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        .date-input-wrapper {
            position: relative;
            padding: 0 15px 0 0;
            border: 1px solid $blue;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 360px) {
                width: 100%;
                min-width: 250px;
            }
            cursor: pointer;
            .datetime_input {
                width: 100%;
                color: $blue;
                pointer-events: none;
                border-radius: 0;
                border: 0;
                font-family: GalanoGrotesqueB;
                background-color: $white;
            }
            .icon_chevron {
                color: $blue;
            }
        }
        select {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .apptrecords_container {
        margin-top: 30px;
        .apptrecords_content {
            text-align: center;
            margin-top: 10px;
            .apptrecords_row {
                margin-top: 15px;
                .col-1 {
                    margin-right: 10px;
                    font-family: GalanoGrotesqueB;

                    @media (max-width: 768px) {
                        flex: inherit;
                        max-width: 25%;
                    }
                }
                .col-2 {
                    @media (max-width: 545px) {
                        flex: inherit;
                        max-width: 20.5%;
                    }
                }
                p {
                    font-family: GalanoGrotesqueB;
                }
                input,
                textarea {
                    border-radius: 0;
                    border-width: 1.5px;
                    border-color: $blue;
                    background-color: transparent;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                input {
                    text-align: center;
                }
            }
            .apptrecords_textarea {
                margin-top: 15px;
                .col-1 {
                    font-family: GalanoGrotesqueB;
                    margin-right: 10px;
                    @media (max-width: 768px) {
                        flex: inherit;
                        max-width: 25%;
                    }
                }
                .col-2 {
                    @media (max-width: 768px) {
                        flex: inherit;
                        max-width: 50%;
                    }
                    @media (max-width: 545px) {
                        max-width: 60%;
                        padding: 0;
                    }
                }
                textarea {
                    border-radius: 0;
                    border-width: 1.5px;
                    border-color: $blue;
                    margin: 5px 0;
                    background-color: transparent;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            div {
                font-size: 13px;
                @media (max-width: 545px) {
                    font-size: 11.5px;
                }
                color: $blue;
            }
        }
    }
    .performance-assesment {
        margin-top: 45px;
        min-height: 150px;
        width: 80%;

        @media (max-width: 960px) {
            width: 100%;
        }

        .performance-lists {
            padding: 0;
            .performance-item {
                margin: 10px;
                list-style-type: none;
                display: flex;
                justify-content: space-between;
                p {
                    margin: 0;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                }
                .dropdown {
                    width: 100px;
                    .toggle-btn {
                        padding: 5px 15px;
                        height: 35px;
                        width: 125px;
                        color: $blue;
                        font-size: 12px;
                        border-radius: 0;
                        border: 1.5px solid $blue;
                        background-color: white;
                        font-family: GalanoGrotesqueB;
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        border: 1px solid $blue;
                        background-color: $white;
                        overflow: hidden;
                        max-height: 200px;

                        button {
                            padding: 10px;
                            color: $darkGray;
                            border-bottom: 1px solid $blue;

                            &:hover {
                                background-color: $blue;
                                color: $white;
                            }

                            &:last-child {
                                border: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .button_container {
        width: 80%;
        margin: 35px 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 425px) {
            flex-direction: column;
        }

        .button-group {
            display: flex;

            button:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}

.cancel-appt-popup {
    &__container {
        text-align: center;

        .miyosmart-title {
            margin-top: 20px;
        }

        i {
            color: $blue;
        }
    }

    &__text {
        font-size: 20px;
    }

    &__button-container {
        width: 100%;
        display: flex;

        button {
            margin: 10px;
        }
    }
}
