.create-appt-calendar {
    position: fixed;
    z-index: 100;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    .calendar-wrapper {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            top: -20px;
            left: 0;
            right: -20px;
            bottom: 0;
            border-radius: 20px;
            background-color: $blue;
        }

        .appt-calendar {
            display: flex;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    i:hover {
        .calendar_button {
            color: $blue;
            font-size: 25px;
            &:hover {
                background-color: $blue;
            }
        }
    }
    .react-calendar {
        position: relative;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 100%;
        max-width: 550px;
        min-width: 325px;
        padding: 30px;
        z-index: 1;
        border: 0;
        border-right: 1px solid $gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 600px) {
            width: 100%;
            max-width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
        }

        &__navigation {
            width: 100%;

            &__label {
                color: $blue;
                font-family: GalanoGrotesqueB;
                font-size: 20px;
                pointer-events: none;
                text-transform: uppercase;
            }
        }

        &__month-view__weekdays__weekday {
            padding: 5px 0 20px;
        }

        &__tile {
            padding: 0 !important;
            &:disabled {
                background-color: transparent;

                abbr {
                    color: darken($gray, 5%);
                }
            }

            &--active {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    content: "";
                    background: $beige;
                    border-radius: 40px;
                    z-index: -10;
                }
            }

            &--hasActive {
                &:hover {
                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 30px;
                        height: 30px;
                        display: block;
                        content: "";
                        background: $beige;
                        border-radius: 40px;
                        z-index: -10;
                    }
                }
            }
        }

        abbr {
            text-decoration: none;
            font-size: 14px;
            color: black;
            z-index: 999999;
        }

        button {
            position: relative;
            background-color: transparent !important;
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
            flex-basis: inherit;
            max-width: inherit;
            height: 46px;
            @media (max-width: 390px) {
                height: 36px;
            }
            &:hover,
            &:focus {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    content: "";
                    background: $beige;
                    border-radius: 40px;
                    z-index: -10;
                }
            }
        }

        .react-calendar__navigation button {
            background-color: $white;
            &:hover {
                background-color: $blue;
                color: $white;
                border-radius: 40px;
            }
            &:focus {
                outline: none;
            }
        }

        .react-calendar__month-view__weekdays__weekday {
            &:nth-child(6) {
                color: $red;
            }
            &:last-child {
                color: $red;
            }
        }
    }

    .timeselect_container {
        background-color: white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 1;
        width: 100%;
        max-width: 250px;

        @media (max-width: 600px) {
            border-top-right-radius: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            max-width: 100%;
        }

        .timeselect_title {
            display: grid;
            justify-content: center;
            text-align: center;
            padding: 20px;

            .miyosmart-title {
                font-size: 18px !important;
            }

            button {
                margin: 5px;
                padding: 10px 15px;
                border: none;
                color: black;
                font-size: 14px;
                font-family: GalanoGrotesqueB;
                border-radius: 20px;
                background-color: $white;

                &:focus {
                    outline: 0;
                }
                &.active {
                    background-color: $beige;
                }

                &:disabled {
                    opacity: 0.5;
                    text-decoration: line-through;
                    color: $darkGray;
                    border: 1px solid $darkGray;
                    background-color: $white;
                }
            }
        }
    }
}

.createappt_container {
    padding: 50px;

    @media (max-width: 640px) {
        padding: 25px;
    }

    .createappt_search {
        color: $darkGray;

        label {
            font-size: 14px;
        }

        .ordernum_container {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 400px;
            border: 1px solid $blue;
            padding: 0 15px 0 0;

            .icon_search {
                color: $blue;
            }

            .ordernum_input {
                border-radius: 0;
                font-family: GalanoGrotesqueB;
                color: $blue;
                border: 0;

                &::placeholder {
                    font-family: GalanoGrotesqueB;
                    color: $blue;
                }
            }

            .order-number-list {
                position: absolute;
                top: 100%;
                left: -1px;
                right: -1px;
                padding: 0;
                height: 200px;
                color: $darkGray;
                overflow: auto;
                list-style-type: none;
                background-color: $white;
                border: 1px solid $blue;
                z-index: 99999;

                .order-number-item {
                    padding: 15px 0;
                    padding-left: 20px;
                    border-bottom: 1.5px solid $gray;
                    &:hover {
                        cursor: pointer;
                        background-color: $blue;
                        color: $white;
                    }
                }
            }
        }
        .profile_input_container {
            margin-top: 15px;
            label {
                margin-top: 10px;
                font-size: 14px;
            }
            input {
                border-radius: 0;
                border-width: 1.5px;
                border-color: $blue;
                color: $blue;
                width: 100%;
                max-width: 400px;

                &::placeholder {
                    font-family: GalanoGrotesqueB;
                }
            }
        }

        ::placeholder {
            color: $blue;
            font-size: 16px;
        }
        input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .datetime_container {
        margin-top: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        .date-input-wrapper {
            position: relative;
            padding: 0 15px 0 0;
            border: 1px solid $blue;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 360px) {
                width: 100%;
                min-width: 250px;
            }
            cursor: pointer;
            .datetime_input {
                width: 100%;
                color: $blue;
                pointer-events: none;
                border-radius: 0;
                border: 0;
                font-family: GalanoGrotesqueB;
                background-color: $white;
            }
            .icon_chevron {
                color: $blue;
            }
        }
        select {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .button_container {
        display: flex;
        margin-top: 30px;
        @media (max-width: 350px) {
            display: grid;
            justify-content: center;
        }
        button {
            margin-right: 2%;
        }
    }
}
