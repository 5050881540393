.appthistory_container {
    padding: 50px;

    @media (max-width: 640px) {
        padding: 25px;
    }

    .appthistory_search {
        label {
            font-size: 14px;
            color: $darkGray;
        }

        .ordernum_container {
            position: relative;
            padding: 0 15px 0 0;
            width: 100%;
            max-width: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            border: 1px solid $blue;

            .icon_search {
                color: $blue;
            }
            .ordernum_input {
                border: 0;
                border-radius: 0;
                font-family: GalanoGrotesqueB;
                color: $blue;
                background-color: transparent;

                &::placeholder {
                    font-family: GalanoGrotesqueB;
                    color: $blue;
                }
            }
            input {
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        ::placeholder {
            color: $blue;
            font-size: 16px;
        }
    }
    .datetime_container {
        margin-top: 30px;
        width: 100%;
        max-width: 400px;

        .dropdown {
            .time-toggle-btn {
                width: 100%;
                border-radius: 0;
                border-width: 1.5px;
                border-color: $blue;
                font-family: GalanoGrotesqueB;
                color: $blue;
                background-color: $white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:focus {
                    outline: none;
                    box-shadow: none !important;
                }
                &:active {
                    border-color: inherit;
                    color: $blue;
                    background-color: $white;
                }
            }
            .dropdown-menu {
                padding: 0;
                border-radius: 0;
                border: 1px solid $blue;
                background-color: $white;
                overflow: hidden;
                max-height: 200px;

                button {
                    padding: 10px;
                    color: $darkGray;
                    border-bottom: 1px solid $blue;

                    &:hover {
                        background-color: $blue;
                        color: $white;
                    }

                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
        .datetime_input {
            border-radius: 0;
            border-width: 1.5px;
            border-color: $blue;
            color: $blue;
        }
        select {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .apptrecords_container {
        margin-top: 30px;
        .apptrecords_content {
            text-align: center;
            margin-top: 10px;
            .apptrecords_row {
                margin-top: 15px;
                .col-1 {
                    margin-right: 10px;
                    font-family: GalanoGrotesqueB;
                    @media (max-width: 768px) {
                        flex: inherit;
                        max-width: 25%;
                    }
                }
                .col-2 {
                    font-family: GalanoGrotesqueB;
                    @media (max-width: 545px) {
                        flex: inherit;
                        max-width: 20.5%;
                        padding: 2px;
                    }
                }
                input,
                textarea {
                    border-radius: 0;
                    border-width: 1.5px;
                    border-color: $blue;
                    font-family: GalanoGrotesqueB;
                    background-color: transparent;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                input {
                    text-align: center;
                }
            }
            .apptrecords_textarea {
                margin-top: 15px;

                .col-1 {
                    font-family: GalanoGrotesqueB;
                    margin-right: 10px;
                    @media (max-width: 768px) {
                        flex: inherit;
                        max-width: 25%;
                    }
                }
                .col-2 {
                    font-family: GalanoGrotesqueB;
                    @media (max-width: 768px) {
                        flex: inherit;
                        max-width: 50%;
                    }
                    @media (max-width: 545px) {
                        max-width: 60%;
                        padding: 0;
                    }
                }
                textarea {
                    border-radius: 0;
                    border-width: 1.5px;
                    border-color: $blue;
                    font-family: GalanoGrotesqueB;
                    background-color: transparent;
                    color: $blue;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            div {
                font-size: 13px;
                @media (max-width: 545px) {
                    font-size: 11.5px;
                }
                color: $blue;
            }
        }
    }
    .performance-assesment {
        margin-top: 45px;
        min-height: 150px;
        width: 59%;
        @media (max-width: 960px) {
            width: 100%;
        }
        .performance-lists {
            padding: 0;
            pointer-events: none;
            .performance-item {
                margin: 10px;
                list-style-type: none;
                display: flex;
                justify-content: space-between;
                p {
                    margin: 0;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                }
                .dropdown {
                    width: 100px;
                    .toggle-btn {
                        padding: 5px 15px;
                        height: 35px;
                        width: 125px;
                        color: $blue;
                        font-size: 12px;
                        border-radius: 0;
                        border: 1.5px solid $blue;
                        background-color: white;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    .dropdown-menu {
                        padding: 0;
                        overflow: hidden;
                        border: 0;
                        button {
                            font-size: 12px;
                            color: $darkGray;
                            font-weight: 500;
                            border-bottom: 1px solid $gray;
                            &:hover {
                                color: #fff;
                                background-color: $blue;
                            }
                        }
                    }
                }
            }
        }
    }
    .button_container {
        width: 80%;
        margin: 35px 0;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 425px) {
            flex-direction: column;
        }

        .button-group {
            display: flex;

            button:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
