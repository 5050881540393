.popup-container-months {
    text-align: center;

    .popup-months-button {
        border-radius: 30px;
        margin: 10px;
        height: 45px;
        width: 125px;
        color: $blue;
        border: 2px solid $blue;
        background-color: #fff;
        font-family: GalanoGrotesqueB;

        &:hover {
            color: #fff;
            background-color: $blue;
        }
    }
}

.popup-container-order {
    text-align: center;

    .popup-order-date {
        color: $blue;
    }

    .popup-order-button {
        border-radius: 30px;
        margin: 10px;
        height: 45px;
        width: 125px;
        color: $blue;
        border: 2px solid $blue;
        background-color: #fff;
        font-family: GalanoGrotesqueB;

        &:hover {
            color: #fff;
            background-color: $blue;
        }
    }
}

.rbc-calendar {
    padding: 10px 10px 30px;
    min-width: calc(100vw - 300px);
    min-height: calc(100vh - 80px);

    .rbc-toolbar {
        padding: 20px 0;

        .rbc-toolbar-label {
            color: $blue;
            font-size: 30px;
            cursor: pointer;
            font-family: GalanoGrotesqueB;
            text-transform: uppercase;
        }
    }
    .rbc-month-view {
        border: 1px solid $blue;
        background-color: #e2e3e8;
    }

    .rbc-month-header {
        background-color: $blue;
    }

    .rbc-header {
        padding: 10px;
        margin: 5px;
        color: $blue;
        border: 0;
        background-color: transparent;

        span {
            font-size: 18px;
            font-family: GalanoGrotesqueB;
            color: $white;
            text-transform: uppercase;
        }
    }

    .rbc-month-row {
        border: none;
        color: $blue;

        .rbc-day-bg {
            border-right: 1px solid $blue;
            border-bottom: 1px solid $blue;
            border-left: 0;
            background-color: $white;

            &:last-child {
                border-right: 0;
            }

            &.rbc-today {
                background-color: darken($blue, 15%);
            }

            &.rbc-off-range-bg {
                background-color: darken(#fff, 8%);
            }

            &:first-child {
                background-color: $beige;

                &.rbc-off-range-bg {
                    background-color: darken(#fff, 8%);
                }
            }

            &:last-child {
                background-color: $beige;
            }
        }
        .rbc-row-content {
            height: 100%;
            cursor: pointer;
            .rbc-row {
                cursor: pointer;
                .rbc-date-cell {
                    &.rbc-now {
                        a {
                            color: $white;
                            font-weight: bold;
                        }
                    }
                    a {
                        margin-right: 5px;
                        font-size: 12px;
                        text-decoration: none;
                        pointer-events: none;
                    }
                }
                .rbc-row-segment {
                    padding: 1px;
                    display: flex;
                    justify-content: center;

                    .rbc-event-content {
                        padding: 6px;
                    }
                }
            }
        }
    }
    .rbc-event {
        color: $white;
        text-align: center;
        background-color: $blue;
        border-radius: 20px;
        font-size: 12px;
        width: 80%;
        margin: 0;
        padding: 2px 0;
        &:hover {
            background-color: lighten($blue, 10%);
        }
        &:focus {
            outline: none;
        }
    }
}

.rbc-btn-group {
    color: $blue;
    display: flex;

    .icon-left {
        position: relative;
        right: 6em;
    }
    .icon-right {
        position: relative;
        left: 6em;
    }
}

.rbc-overlay {
    padding: 15px;

    .rbc-overlay-header {
        color: $blue;
        font-family: GalanoGrotesqueB;
        margin-bottom: 10px;
        border: 0;
    }

    .rbc-event {
        margin: 5px 0;
        padding: 10px;
        border-radius: 100px;
        text-align: center;
    }
}
