.layout_container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $beige;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
        min-height: calc(100vh - 80px);
    }

    .logo-container {
        position: absolute;
        width: 180px;

        @media (min-width: 960px) {
            left: 30px;
            top: 30px;
        }

        @media (max-width: 960px) {
            position: relative;
            margin: 30px auto;
        }

        a {
            display: block;
            img {
                width: 100%;
            }
        }
    }
}
