//Colors
$beige: #f1d4a5;
$blue: #095da7;
$red: #e92e28;
$darkGray: #a7a9ac;

$gray: #e2e3e8;
$lightGray: #edeff4;
$white: #ffffff;
$lightGold: #fded20;
$gold: #f7991c;

* {
    line-height: 1.2;
    box-sizing: border-box;
}
*:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 35px white inset;
}

::-webkit-scrollbar {
    height: 5px;
    width: 8px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $darkGray;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.75);
}
::-webkit-scrollbar-corner {
    background: transparent;
}
