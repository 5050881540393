.clientdetails_container {
    padding: 50px;

    @media (max-width: 640px) {
        padding: 25px;
    }

    .ordernum_container {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 50%;

        @media (max-width: 486px) {
            width: 100%;
        }

        label {
            font-size: 14px;
            color: $darkGray;
        }

        .input-wrapper {
            padding: 0 15px 0 0;
            border: 1px solid $blue;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }

        .icon_search {
            color: $blue;
        }

        .ordernum_input {
            border: 0;
            border-radius: 0;
            font-family: GalanoGrotesqueB;
            color: $blue;
            background-color: transparent;

            &::placeholder {
                font-family: GalanoGrotesqueB;
            }
        }
        input {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        ::placeholder {
            color: $blue;
            font-size: 16px;
        }
    }

    .card_container {
        .card {
            border: 0;

            .card-body {
                padding: 0;

                label {
                    font-size: 14px;
                    color: $darkGray;
                }

                p {
                    font-family: GalanoGrotesqueB;
                }
            }
            .contact_container {
                padding: 0;

                label {
                    font-size: 14px;
                    color: $darkGray;
                }

                p {
                    font-family: GalanoGrotesqueB;
                }

                .contact_content {
                    padding-top: 1.25em;
                }
            }

            .appointment_container {
                padding-top: 1.25em;
                padding-left: 0;

                label {
                    font-size: 14px;
                    color: $darkGray;
                }

                p {
                    font-family: GalanoGrotesqueB;
                }

                ol {
                    list-style-type: none;

                    li {
                        font-family: GalanoGrotesqueB;
                    }
                }
            }
        }
    }
}
