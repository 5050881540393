.miyosmart-button {
    width: 100%;
    max-width: 250px;
    min-width: 150px;
    padding: 12px 10px;
    margin: 5px 0;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    border-radius: 30px;
    background-color: $blue;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    &:focus {
        outline: 0;
    }
    &:hover {
        background-color: darken($blue, 5%);
    }

    &.outline {
        color: $blue;
        background-color: $white;
        border: 1px solid $blue;

        &:hover {
            background-color: darken($white, 2%);
        }
    }
}
