.footer_container {
    position: relative;
    width: 100%;
    height: 80px;
    color: white;
    background-color: $blue;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 320px) {
        flex-direction: column;
        justify-content: center;
    }

    .footer_text_right {
        display: flex;
        .footer_text_right_p {
            padding-right: 10px;
        }
    }

    .footer_text_right,
    .footer_text_left {
        p {
            margin: 0;
            font-size: 14px;
            a {
                text-decoration: none;
                color: white;
            }
        }
    }
}
