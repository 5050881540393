.super-container {
    padding: 50px;
    @media (max-width: 768px) {
        padding: 30px 15px !important;
    }
    &.s-home-container {
        width: 100%;
        max-width: 1024px;
        margin-bottom: 120px;

        .card-container {
            &:not(:first-child) {
                margin-top: 40px;
            }
            .card {
                .card-body {
                    table {
                        thead {
                            tr {
                                th {
                                    &:first-child {
                                        width: 20%;
                                        min-width: 112px;
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                .action {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
