.promo-container {
    .promo-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .promo-title {
            font-size: 35px;
            color: $blue;
            .add-icon {
                cursor: pointer;
                margin-left: 20px;
            }
        }
    }
    .row {
        width: 100%;
        margin: 0;
        .col {
            width: 18%;
            min-width: 175px;
            margin: 5px;
            padding: 0;
            border-radius: 12px;
            overflow: hidden;
            background-color: $white;
            flex-grow: inherit;
            flex-basis: inherit;
            .promo-img {
                min-height: 100px;
                width: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top;
            }
            .promo-content {
                padding: 10px;
                .promo-name {
                    margin: 0;
                    color: $blue;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .promo-controller {
                    text-align: right;
                    color: $blue;
                    i {
                        margin: 2.5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
