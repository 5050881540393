.modal {
    display: flex !important;
    align-items: center !important;
    .rredemption_modalContainer {
        width: 100%;
        margin: auto;
        height: 500px;

        &:before {
            position: absolute;
            content: "";
            top: -20px;
            left: 0;
            right: -20px;
            bottom: 0;
            border-radius: 20px;
            background-color: $blue;
        }

        .rredemption_modalContent {
            margin: 0;
            // margin: 30vh auto;
            height: 100%;
            flex-direction: column !important;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            color: $blue;
            border-radius: 20px;
            border: none;
            .rredemption_mc-img1 {
                position: absolute;
                bottom: -1px;
                right: -2px;
                height: 100px;
            }
            .rredemption_mc-img2 {
                position: absolute;
                height: 200px;
                top: -2px;
                left: -2px;
            }
            .rredemption_popuptitle {
                text-align: center;
                font-size: 30px;
                margin-top: 30px;
                z-index: 99;
                font-family: GalanoGrotesqueB;
            }

            .rredemption_formStyle {
                width: 100%;
                max-width: 640px;
                padding: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 99;
                .rredemption_text-input {
                    padding: 5px;
                    margin: 10px;
                    width: 100%;
                    height: 40px;
                    border-radius: 35px;
                    font-family: GalanoGrotesqueB;
                    color: $blue;
                    border: 1.5px solid $blue;
                    text-align: center;
                    background-color: #fff !important;
                }
                .rredemption_text-input::placeholder {
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                }

                .rredemption-form-label {
                    margin: 0;
                    font-weight: 600px;
                    font-size: 20px;
                    color: $darkGray;
                    text-transform: uppercase;
                }
                .rredemption_ButtonContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin-top: 50px;
                    width: 100%;
                    .outline {
                        background-color: #fff;
                    }
                }
                .text-input {
                    padding: 5px;
                    margin: 10px;
                    width: 100%;
                    height: 40px;
                    border-radius: 35px;
                    border: 1.5px solid #2f368f;
                    text-align: center;
                    background-color: #fff !important;
                    &:active,
                    &:focus {
                        outline: none;
                    }
                    &.error {
                        border-color: $red;
                    }
                }
                .input-feedback {
                    color: $red;
                }
            }
        }
    }
}

.s-redemptions-dashboard-container {
    .back-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $blue;
        line-height: 1;
        cursor: pointer;
        p {
            font-size: 16px;
            font-family: GalanoGrotesqueB;
        }
        i {
            margin-right: 5px;
            display: flex !important;
        }
    }

    .col {
        &.col-flex {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
            padding: 45px 15px 15px;
            border-top: 1px solid $gray;
            @media (max-width: 1024px) {
                flex-direction: column;
            }
        }
        div {
            .redemption-details-points {
                margin-right: 50px;
                margin-top: 50px;
                font-family: GalanoGrotesqueB;
                display: flex;
                justify-content: flex-end;
                color: $blue;

                h5 {
                    align-items: center;
                    display: flex;
                    font-family: GalanoGrotesqueB;
                    margin-left: 30px;
                    font-size: 16px;
                    margin-bottom: 0px;
                    color: $blue;
                }
            }
        }
        .shipping-card-container {
            width: 100%;
            .card-title {
                font-size: 28px;
                color: $blue;
                font-family: GalanoGrotesqueB;
            }
            .shipping-details-card {
                padding: 15px;
                border-radius: 0;
                border: 1.5px solid $blue;

                .sd-card-role {
                    margin: 0;
                    width: 100%;
                    border-bottom: 1px solid $blue;
                    pointer-events: none;
                    &:last-child {
                        border: 0;
                    }
                    .col {
                        padding: 10px;
                        pointer-events: auto;
                        &.edit-col {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $blue;
                            cursor: pointer;
                            pointer-events: auto;
                        }
                        .label {
                            font-size: 14px;
                            color: $darkGray;
                            margin: 0;
                            @media only screen and (max-width: 380px) {
                                font-size: 12px;
                            }
                        }
                        .desc {
                            margin: 5px 0 0;
                            font-family: GalanoGrotesqueB;
                            font-size: 16px;
                            line-height: 1.2;
                            @media only screen and (max-width: 380px) {
                                font-size: 14px;
                            }
                        }
                        .sd-details-icon-style {
                            color: $blue;
                        }
                    }
                    .col-1 {
                        @media only screen and (max-width: 768px) {
                            padding-right: 30px;
                        }
                    }
                }
            }
            &:nth-of-type(1) {
                width: 55%;
                @media (max-width: 1024px) {
                    width: 100%;
                }
            }
            &:nth-of-type(2) {
                width: 35%;
                min-width: 330px;
                margin-left: 30px;
                @media (max-width: 1024px) {
                    width: 100%;
                    margin-left: 0px;
                }
                @media (max-width: 450px) {
                    min-width: 0px;
                }
                .col-1 {
                    display: none;
                }
                .label {
                    color: $blue !important;
                }
                .desc:nth-of-type(1) {
                    margin-top: 5px;
                }
                .desc:nth-of-type(2) {
                    color: gray;
                    font-size: 10px !important;
                    white-space: nowrap;
                }
            }
        }
    }
    .card-container {
        .card {
            .card-body {
                max-height: 100%;
                table {
                    thead {
                        tr {
                            white-space: nowrap;
                        }
                    }
                    tbody {
                        tr {
                            .edit {
                                text-align: right;
                                white-space: nowrap;
                            }
                            .name {
                                min-width: 100px;
                            }
                            .email {
                                word-break: break-all;
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}
