.login-card {
    position: relative;
    width: 100%;
    min-width: 325px;
    max-width: 640px;

    @media (max-width: 768px) {
        margin: 35px;
        width: 100%;
        max-width: 100%;
    }

    &__outer-layer {
        position: absolute;
        top: -15px;
        right: -15px;
        bottom: 0;
        left: 0;
        border-radius: 20px;
        background-color: $blue;
    }

    &__miyobee {
        position: absolute;
        bottom: -50px;
        right: -110px;
        height: 180px;
        width: 180px;
        z-index: 99;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../assets/img/miyobee.png");

        @media (max-width: 860px) {
            display: none;
        }
    }

    &__container {
        width: 100%;
        height: 425px;
        padding: 35px;
        border-radius: 20px !important;

        @media (max-width: 480px) {
            padding: 15px;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
}
