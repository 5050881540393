.prod-card-container {
    width: 100%;

    .card {
        margin-top: 25px;
        border: 0;

        .card-body {
            padding: 0;

            .row {
                width: 100%;
                margin: 0;
                .col {
                    padding: 15px;
                    width: 50%;
                    border-bottom: 1px solid $gray;
                    @media (max-width: 768px) {
                        label {
                            font-size: 12px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                    .edit {
                        color: $blue;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        text-decoration: underline;
                        i {
                            display: flex;
                        }
                    }
                    label {
                        font-size: 14px;
                        color: $darkGray;
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        font-family: GalanoGrotesqueB;
                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                    &.no-border {
                        border: 0;
                    }
                    ol {
                        counter-reset: item;
                        margin-left: -40px;
                        li {
                            display: flex;
                            align-items: center;
                            line-height: 1;
                            margin: 5px 0;
                            font-size: 14px;
                            font-family: GalanoGrotesqueB;
                            text-decoration: underline;
                            cursor: pointer;

                            @media (max-width: 640px) {
                                flex-direction: column;
                            }
                        }

                        label {
                            color: $gray;
                        }

                        text {
                        }

                        .request-slot {
                            color: $red;
                            width: 50%;
                            text-align: right;

                            @media (max-width: 640px) {
                                margin: 5px 0;
                                text-align: left;
                                width: 100%;
                            }
                        }
                    }
                }
                .column-2 {
                    padding: 0;
                }
            }
        }
    }
}
