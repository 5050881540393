.reset-password_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .forgotpass_text {
        font-size: 20px;
        margin-bottom: 35px;
    }

    .reset-password_content {
        width: 100%;

        .formStyle {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .icon_container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 5px;

                i {
                    position: absolute;
                    right: 25px;
                    color: $blue;
                    cursor: pointer;
                }

                .text-input {
                    height: 50px;
                    width: 100%;
                    color: $blue;
                    font-family: GalanoGrotesqueB;
                    border: 1.5px solid $blue;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;
                    display: flex;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: $blue;
                        font-weight: bold;
                    }
                }
            }
            .input-feedback {
                margin: 5px;
                color: $red;
                font-size: 12px;
                font-weight: bold;
            }

            .reset-button {
                margin-top: 25px;
            }
        }
    }
}
