.warranty-request {
    padding: 50px;

    @media (max-width: 640px) {
        padding: 25px;
    }

    .miyosmart-title {
        margin-bottom: 25px;
    }

    &__input {
        margin-bottom: 35px;
        display: flex;
        flex-direction: column;

        label {
            font-size: 14px;
            font-family: GalanoGrotesqueB;
            color: $blue;
            margin-bottom: 20px;
        }

        textarea {
            padding: 10px;
            width: 100%;
            min-height: 100px;
            max-width: 640px;
            color: $blue;
            font-family: GalanoGrotesqueB;
            pointer-events: auto;
            border: 1.5px solid $blue;

            &::placeholder {
                color: $blue;
                font-family: GalanoGrotesqueB;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    &__checkbox {
        display: flex;
        flex-direction: column;

        label {
            font-size: 14px;
            font-family: GalanoGrotesqueB;
            color: $blue;
            margin-bottom: 20px;
        }

        a {
            color: $blue;
            text-decoration: underline;
        }
    }

    &__error {
        margin-top: 5px;
        color: $red;
        font-family: GalanoGrotesqueB;
        font-size: 12px;
    }

    &__button-container {
        button {
            margin-right: 10px;
        }
    }
}

.warranty-request-pop-up {
    &__container {
        text-align: center;

        .miyosmart-title {
            margin-top: 20px;
        }

        i {
            color: $blue;
        }
    }

    &__text {
        font-size: 20px;
    }
}
