.resetpass_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .reset-password-content {
        font-size: 20px;
    }
}
