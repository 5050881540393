.s-redemptions-dashboard-container {
    margin-bottom: 120px;

    .card-container {
        .card {
            .card-body {
                max-height: 70vh !important;
                table {
                    thead {
                        tr {
                            white-space: nowrap;
                        }
                    }
                    tbody {
                        tr {
                            .edit {
                                text-align: right;
                                white-space: nowrap;
                            }
                            .name {
                                min-width: 100px;
                            }
                            .email {
                                word-break: break-all;
                                min-width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
}
